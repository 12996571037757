<template>
  <div>
    <swiper
      v-if="cheapestFare && visibleCheapestFare"
      id="slider-component"
      ref="swiperCheapFareRef"
      :class="`swiper-multiple
      ${visibleCheapestFare ? 'visible' : 'hidden'}`"
      :options="swiperOptions"
    >
      <!-- ANCHOR slides -->
      <swiper-slide
        v-for="(dataDate, indexDataDate) in cheapestFare[tabIndex]"
        :key="indexDataDate"
        class="slide mb-50 mt-50"
      >
        <!-- style="height: auto; width: auto !important;" -->
        <b-card
          :class="`mb-0 d-flex-center justify-content-start px-0 h-100 btn font-weight-bold
          ${
            searchFlightArray[tabIndex] === dataDate.date
              ? 'border-warning'
              : 'border-transparent'
          }`"
          :style="`width: fit-content;
          ${
            new Date().getTime() - new Date(dataDate.date).getTime() >
            24 * 60 * 60 * 1000
              ? 'cursor: not-allowed; opacity: 40%; color: #777;'
              : ''
          }
          ${
            searchFlightArray[tabIndex] === dataDate.date
              ? 'background-color: #FFF8E7'
              : ''
          }`"
          no-body
          @click="
            () => {
              if (
                new Date().getTime() - new Date(dataDate.date).getTime() <
                24 * 60 * 60 * 1000
              )
                handleClick(dataDate.date, tabIndex, indexDataDate)
            }
          "
        >
          <h5
            :class="`fw-700 mx-50 text-center mb-0
            ${
              searchFlightArray[tabIndex] === dataDate.date ? 'text-dark' : ''
            }`"
          >
            {{
              `${convertISODateTimeToLLLL(dataDate.date).weekday}, ${
                convertISODateTimeToLLLL(dataDate.date).dayAndMonth
              }`
            }}
          </h5>
          <div
            v-if="!isRoleF3"
            class="d-flex-center"
          >
            <div v-if="dataDate.cheapestFare">
              <div
                v-for="(
                  itemCheapestFare, indexCheapestFare
                ) in dataDate.cheapestFare"
                :key="indexCheapestFare"
                class="text-nowrap d-flex justify-content-between align-items-center py-25 px-50 px-md-1 my-25"
              >
                <span
                  class="mr-50 font-weight-bolder mb-0"
                  :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
                  :style="resolveBorderColorByAirline(itemCheapestFare.airline)"
                >
                  {{ itemCheapestFare.airline }}
                </span>

                <span
                  :class="`mb-0 fw-700 text-dark
                  ${
                    searchFlightArray[tabIndex] === dataDate.date
                      ? 'text-dark'
                      : 'text-muted'
                  }
                  ${isMobileView ? 'font-small-3' : 'font-medium-1'}
                  `"
                  :style="resolveBorderColorByAirline(itemCheapestFare.airline)"
                >
                  {{ formatCurrency(itemCheapestFare.netFare) }}
                </span>
              </div>
            </div>
            <h6
              v-else
              class="mb-0"
            >
              {{ $t('flight.cheapestFareNotFound') }}
            </h6>
          </div>
        </b-card>
      </swiper-slide>

      <!-- ANCHOR Arrows -->
      <div
        slot="button-prev"
        class="swiper-button-prev"
      >
        <b-button
          variant="white"
          class="rounded-circle border d-flex-center p-50 p-lg-75"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            class="text-body"
            size="30"
          />
        </b-button>
      </div>

      <div
        slot="button-next"
        class="swiper-button-next"
      >
        <b-button
          variant="white"
          class="rounded-circle border d-flex-center p-50 p-lg-75"
        >
          <feather-icon
            icon="ChevronRightIcon"
            class="text-body"
            size="30"
          />
        </b-button>
      </div>
    </swiper>
  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

import store from '@/store'

import {
  convertISODateTime,
  formatCurrency,
  formatDateMonth,
  getArrayDatesBetween,
  convertISODateTimeToLLLL,
  resolveBorderColorByAirline,
} from '@core/utils/filter'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BCard,
    BButton,
    Swiper,
    SwiperSlide,
  },
  setup(_, { emit }) {
    const {
      tabIndex,
      FLIGHT_APP_STORE_MODULE_NAME,
      fetchCheapestFare,
      searchClassBooking,
    } = useBookingHandle()

    const initialSlideArray = ref([])
    const initialSlide = ref()
    const swiperCheapFareRef = ref()
    const DAY_RANGE = 7

    function handleSlideTo(slide) {
      if (swiperCheapFareRef.value) {
        swiperCheapFareRef.value.$swiper.slideTo(slide, 100, false)
      } else {
        let retryCount = 0
        const MAX_RETRY_COUNT = 6

        const intervalHandleSlideTo = setInterval(() => {
          if (retryCount === MAX_RETRY_COUNT) {
            clearInterval(intervalHandleSlideTo)
          } else {
            retryCount += 1
            console.warn(`${retryCount}. Wait for slide initialization...`)
            if (swiperCheapFareRef.value) {
              initialSlide.value = initialSlideArray.value[tabIndex.value]
              clearInterval(intervalHandleSlideTo)
              handleSlideTo(initialSlide.value)
            }
          }
        }, 1500)
      }
    }

    const swiperOptions = ({
      // freeMode: true,
      gridMode: true,
      centeredSlides: true,
      grabCursor: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        320: {
          slidesPerView: 3,
          slidesPerGroup: 1,
        },
        540: {
          slidesPerView: 4,
          slidesPerGroup: 1,
        },
        640: {
          slidesPerView: 5,
          slidesPerGroup: 1,
        },
        // 960: {
        //   slidesPerView: 5,
        //   slidesPerGroup: 1,
        // },
        // 1200: {
        //   slidesPerView: 5,
        //   slidesPerGroup: 1,
        // },
        1400: {
          slidesPerView: 7,
          slidesPerGroup: 1,
        },
      },
      spaceBetween: 15,
      initialSlide: initialSlide.value,
      // initialSlide: DAY_RANGE - 1,
    })

    const searchFlightArray = computed(() => store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`]().map(i => formatDateMonth(i.departDate, 'YYYY-MM-DD')))
    const cheapestFare = ref()
    const tempCheapestFare = ref([])
    const visibleCheapestFare = ref()

    async function fetchCheapFare() {
      visibleCheapestFare.value = JSON.parse(
        localStorage.getItem('visibleCheapestFare') || 'true',
      )

      emit('updateToggleCheapest', visibleCheapestFare.value)

      cheapestFare.value = null
      tempCheapestFare.value = []
      const payloadSearch = cloneDeep(
        JSON.parse(localStorage.getItem('searchFlight')),
      )
      const searchFlightArray = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlightArray`]()

      const results = await Promise.all(
        searchFlightArray.map((item, index) => {
          const departDate = new Date(item.departDate)
          const startDate = new Date(
            departDate.getFullYear(),
            departDate.getMonth(),
            departDate.getDate() - DAY_RANGE,
          )
          // if (startDate < new Date()) {
          //   startDate = new Date()
          // }
          const endDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate() + DAY_RANGE * 2,
          )
          const payload = {
            departure: item.startPoint,
            arrival: item.endPoint,
            startDate: formatDateMonth(startDate, 'YYYY-MM-DD'),
            endDate: formatDateMonth(endDate, 'YYYY-MM-DD'),
          }
          tempCheapestFare.value[index] = getArrayDatesBetween(
            payload.startDate,
            payload.endDate,
          )
          return fetchCheapestFare(payload)
        }),
      )

      const valuesDate = results.map(val => {
        if (!val.data) return []

        return val.data.map(date => ({
          ...date,
          cheapestFare: date.cheapestFare.filter(item => payloadSearch.airline
            ? [payloadSearch.airline].includes(item.airline)
            : ['VU'].includes(item.airline)),
        }))
      })

      tempCheapestFare.value.forEach((item, index) => {
        const valArray = valuesDate[index]
        item.forEach((itemDate, indexDate) => {
          const r = valArray.find(v => v.date === itemDate.date)
          if (r) tempCheapestFare.value[index][indexDate] = r
        })
      })

      cheapestFare.value = tempCheapestFare.value

      cheapestFare.value.forEach((cheapItem, cheapIndex) => {
        const depDate = new Date(searchFlightArray[cheapIndex].departDate)
        const departureDate = convertISODateTime(depDate).dateFilter

        cheapItem.forEach((item, itemIndex) => {
          if (item?.cheapestFare?.length) item.cheapestFare.filter(i => ['VN'].includes(i.airline)) // FIXME

          const itemDate = item.date
          if (itemDate === departureDate) {
            initialSlideArray.value[cheapIndex] = itemIndex
          }
        })
      })

      initialSlide.value = initialSlideArray.value[tabIndex.value]
      handleSlideTo(initialSlide.value)
    }

    onMounted(() => {
      fetchCheapFare()
    })

    // search lại thì call lại fetchCheapFare
    watch(searchFlightArray, () => {
      fetchCheapFare()
    }, { deep: true })

    watch(tabIndex, () => {
      initialSlide.value = initialSlideArray.value[tabIndex.value]
      // FIXME: MC 3 hành trình, 1 trong 3 hành trình không trả về cheapest-fare => lỗi index ở hành trình đó
      handleSlideTo(initialSlide.value)
    },
    { deep: true, immediate: true })

    const handleClick = (date, tabIndex, index) => {
      initialSlideArray.value[tabIndex] = index
      const searchFlightPayload = store.getters[`${FLIGHT_APP_STORE_MODULE_NAME}/getSearchFlight`]
      if (searchFlightPayload.type === 'OW') {
        searchFlightPayload.flights[0].departDate = date
      } else if (searchFlightPayload.type === 'RT') {
        if (tabIndex === 0) searchFlightPayload.flights[0].departDate = date
        else if (tabIndex === 1) {
          searchFlightPayload.flights[0].returnDate = date
        }
      } else if (searchFlightPayload.type === 'MC') {
        searchFlightPayload.flights[tabIndex].departDate = date
      }

      searchClassBooking(searchFlightPayload)
    }

    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    return {
      convertISODateTimeToLLLL,
      convertISODateTime,
      formatCurrency,

      handleClick,
      resolveBorderColorByAirline,
      tabIndex,
      isRoleF3,
      swiperOptions,
      swiperCheapFareRef,
      searchFlightArray,
      cheapestFare,
      visibleCheapestFare,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

// .swiper-button-prev.btn-button,
// .swiper-button-next.btn-button {
//   margin-top: 0;
//   height: 70%;
//   translate: 0 -50%;

//   &:hover {
//     background: rgba(96, 172, 212, 0.12);
//     backdrop-filter: blur(5px);
//     border-radius: 8px;
//   }
// }

.swiper-button-prev {
  margin-top: 0;
  translate: -10% -50%;

  &:after {
    background-image: unset !important;
  }
}

.swiper-button-next {
  margin-top: 0;
  translate: 10% -50%;

  &:after {
    background-image: unset !important;
  }
}

#button-show-cheapest-fare ::v-deep {
  z-index: 30;
  right: 15px;
}

.swiper-wrapper ::v-deep {
  transform: none !important;
}
</style>
